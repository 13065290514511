/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ContentfulModule, moduleEntity } from '@h/modules'
import Navbar from 'eevee-components/navbar'

const WrappedNavbar = ({ leftItems = [], middleItems = [], rightItems = [] }) => {
  return (
    <Navbar
      leftItems={
        <For each='item' of={leftItems} index='idx'>
          <ContentfulModule key={idx} {...moduleEntity(item)} />
        </For>
      }
      middleItems={
        <For each='item' of={middleItems} index='idx'>
          <ContentfulModule key={idx} {...moduleEntity(item)} />
        </For>
      }
      rightItems={
        <For each='item' of={rightItems} index='idx'>
          <ContentfulModule key={idx} {...moduleEntity(item)} />
        </For>
      }
    />
  )
}

export default WrappedNavbar
